<template>
<!--    <div class="gallery" v-show="galleryReady">-->
<!--      <div class="cardList">-->
<!--        <button id="plus_one_img" class="cardList__btn btn btn&#45;&#45;left">-->
<!--          <div class="icon">-->
<!--            <svg>-->
<!--              <use xlink:href="#arrow-left"></use>-->
<!--            </svg>-->
<!--          </div>-->
<!--        </button>-->

<!--        <div class="cards__wrapper">-->
<!--          <div class="card current&#45;&#45;card">-->
<!--            <div class="card__image">-->
<!--              <img :src="clearedJson[index].img_url" alt="d" />-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="card next&#45;&#45;card">-->
<!--            <div class="card__image">-->
<!--              <img :src="clearedJson[index + 1].img_url" alt="d" />-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="card previous&#45;&#45;card">-->
<!--            <div class="card__image" >-->
<!--              <img :src="clearedJson[index - 1].img_url" alt="d" />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <button id="minus_one_img" class="cardList__btn btn btn&#45;&#45;right">-->
<!--          <div class="icon">-->
<!--            <svg>-->
<!--              <use xlink:href="#arrow-right"></use>-->
<!--            </svg>-->
<!--          </div>-->
<!--        </button>-->
<!--      </div>-->

<!--      <div class="infoList">-->
<!--        <div class="info__wrapper">-->
<!--          <div class="info current&#45;&#45;info">-->
<!--            <h1 class="text name" v-html="clearedJson[index].title"></h1>-->
<!--            <h4 class="text location" v-html="clearedJson[index].taken_at_location + ', ' + clearedJson[index].taken_on_date"></h4>-->
<!--            <p class="text description" v-html="clearedJson[index].description"></p>-->
<!--          </div>-->

<!--          <div class="info next&#45;&#45;info">-->
<!--            <h1 class="text name" v-html="clearedJson[index + 1].title"></h1>-->
<!--            <h4 class="text location" v-html="clearedJson[index + 1].taken_at_location + ', ' + clearedJson[index + 1].taken_on_date"></h4>-->
<!--            <p class="text description" v-html="clearedJson[index + 1].description"></p>-->
<!--          </div>-->

<!--          <div class="info previous&#45;&#45;info">-->
<!--            <h1 class="text name" v-html="clearedJson[index - 1].title"></h1>-->
<!--            <h4 class="text location" v-html="clearedJson[index - 1].taken_at_location + ', ' + clearedJson[index - 1].taken_on_date"></h4>-->
<!--            <p class="text description" v-html="clearedJson[index - 1].description"></p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="gallery__bg">-->
<!--        <div class="gallery__bg__image current&#45;&#45;image">-->
<!--          <img :src="clearedJson[index].img_url" alt="d" />-->
<!--        </div>-->
<!--        <div class="gallery__bg__image next&#45;&#45;image">-->
<!--          <img :src="clearedJson[index + 1].img_url" alt="d" />-->
<!--        </div>-->
<!--        <div class="gallery__bg__image previous&#45;&#45;image">-->
<!--          <img :src="clearedJson[index -1].img_url" alt="d" />-->
<!--        </div>-->
<!--      </div>-->

<!--      <svg class="icons">-->
<!--        <symbol id="arrow-left" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>-->
<!--          <polyline points='328 112 184 256 328 400'-->
<!--                    style='fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:48px' />-->
<!--        </symbol>-->
<!--        <symbol id="arrow-right" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>-->
<!--          <polyline points='184 112 328 256 184 400'-->
<!--                    style='fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:48px' />-->
<!--        </symbol>-->
<!--      </svg>-->
<!--    </div>-->
  <div>
    <div class="gallery" v-show="galleryReady">
      <div v-for="index in clearedJson" :key="index.id" class="img-list">
        <ul>
          <li>{{ index.title }}</li>
          <li>{{ index.event }}</li>
          <li>{{ index.taken_on_date }}</li>
          <li>{{ index.taken_at_location }}</li>
          <li>{{ index.description }}</li>
        </ul>
        <img :src="index.img_url" alt="x" height="400" width="600"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Gallery',
  props: ['clearedJson'],
  data() {
    return {
      galleryReady: false
    }
  },
  mounted() {
    this.showGallery()
    //this.startAnimations()
  },
  methods: {
    showGallery() {
      this.galleryReady  = true
      return this.galleryReady
    },
    // startAnimations() {
    //   const buttons = {
    //     prev: document.querySelector(".btn--right"),
    //     next: document.querySelector(".btn--left"),
    //   };
    //
    //   const cardsContainerEl = document.querySelector(".cards__wrapper");
    //   const galleryBgContainerEl = document.querySelector(".gallery__bg");
    //
    //   const cardInfosContainerEl = document.querySelector(".info__wrapper");
    //   buttons.next.addEventListener("click", () => swapCards("right"));
    //   buttons.prev.addEventListener("click", () => swapCards("left"));
    //
    //   const btnPlus = document.getElementById('plus_one_img')
    //   const btnMinus = document.getElementById('minus_one_img')
    //   btnPlus.addEventListener('click', reindexPlus)
    //   btnMinus.addEventListener('click', reindexMinus)
    //   function reindexPlus() { return this.index += 1; }
    //   function reindexMinus() { return this.index -= 1; }
    //
    //   const currentCardEl = cardsContainerEl.querySelector(".current--card");
    //   const previousCardEl = cardsContainerEl.querySelector(".previous--card");
    //   const nextCardEl = cardsContainerEl.querySelector(".next--card");
    //
    //   const currentBgImageEl = galleryBgContainerEl.querySelector(".current--image");
    //   const previousBgImageEl = galleryBgContainerEl.querySelector(".previous--image");
    //   const nextBgImageEl = galleryBgContainerEl.querySelector(".next--image");
    //
    //   function swapCards(direction) {
    //     changeInfo(direction)
    //     swapCardsClass()
    //     removeCardEvents(currentCardEl);
    //   }
    //
    //   function swapCardsClass() {
    //     currentCardEl.classList.remove("current--card");
    //     previousCardEl.classList.remove("previous--card");
    //     nextCardEl.classList.remove("next--card");
    //
    //     currentBgImageEl.classList.remove("current--image");
    //     previousBgImageEl.classList.remove("previous--image");
    //     nextBgImageEl.classList.remove("next--image");
    //
    //     currentCardEl.style.zIndex = "50";
    //     currentBgImageEl.style.zIndex = "-2";
    //
    //     if (direction === "right") {
    //       previousCardEl.style.zIndex = "20";
    //       nextCardEl.style.zIndex = "30";
    //
    //       nextBgImageEl.style.zIndex = "-1";
    //
    //       currentCardEl.classList.add("previous--card");
    //       previousCardEl.classList.add("next--card");
    //       nextCardEl.classList.add("current--card");
    //
    //       currentBgImageEl.classList.add("previous--image");
    //       previousBgImageEl.classList.add("next--image");
    //       nextBgImageEl.classList.add("current--image");
    //     } else if (direction === "left") {
    //       previousCardEl.style.zIndex = "30";
    //       nextCardEl.style.zIndex = "20";
    //
    //       previousBgImageEl.style.zIndex = "-1";
    //
    //       currentCardEl.classList.add("next--card");
    //       previousCardEl.classList.add("current--card");
    //       nextCardEl.classList.add("previous--card");
    //
    //       currentBgImageEl.classList.add("next--image");
    //       previousBgImageEl.classList.add("current--image");
    //       nextBgImageEl.classList.add("previous--image");
    //     }
    //   }
    //
    //   function changeInfo(direction) {
    //     let currentInfoEl = cardInfosContainerEl.querySelector(".current--info");
    //     let previousInfoEl = cardInfosContainerEl.querySelector(".previous--info");
    //     let nextInfoEl = cardInfosContainerEl.querySelector(".next--info");
    //
    //     gsap.timeline().to([buttons.prev, buttons.next], {
    //       duration: 0.2,
    //       opacity: 0.5,
    //       pointerEvents: "none",
    //     }).to(
    //         currentInfoEl.querySelectorAll(".text"),
    //         {
    //           duration: 0.4,
    //           stagger: 0.1,
    //           translateY: "-120px",
    //           opacity: 0,
    //         },
    //         "-="
    //     ).call(() => {
    //       swapInfosClass(direction);
    //     }).call(() => initCardEvents()).fromTo(
    //         direction === "right"
    //             ? nextInfoEl.querySelectorAll(".text")
    //             : previousInfoEl.querySelectorAll(".text"),
    //         {
    //           opacity: 0,
    //           translateY: "40px",
    //         },
    //         {
    //           duration: 0.4,
    //           stagger: 0.1,
    //           translateY: "0px",
    //           opacity: 1,
    //         }
    //     ).to([buttons.prev, buttons.next], {
    //       duration: 0.2,
    //       opacity: 1,
    //       pointerEvents: "all",
    //     });
    //
    //     function swapInfosClass() {
    //       currentInfoEl.classList.remove("current--info");
    //       previousInfoEl.classList.remove("previous--info");
    //       nextInfoEl.classList.remove("next--info");
    //
    //       if (direction === "right") {
    //         currentInfoEl.classList.add("previous--info");
    //         nextInfoEl.classList.add("current--info");
    //         previousInfoEl.classList.add("next--info");
    //       } else if (direction === "left") {
    //         currentInfoEl.classList.add("next--info");
    //         nextInfoEl.classList.add("previous--info");
    //         previousInfoEl.classList.add("current--info");
    //       }
    //     }
    //   }
    //
    //   function updateCard(e) {
    //     const card = e.currentTarget;
    //     const box = card.getBoundingClientRect();
    //     const centerPosition = {
    //       x: box.left + box.width / 2,
    //       y: box.top + box.height / 2,
    //     };
    //     let angle = Math.atan2(e.pageX - centerPosition.x, 0) * (35 / Math.PI);
    //     gsap.set(card, {
    //       "$current-card-rotation-offset": `${angle}deg`,
    //     });
    //     const currentInfoEl = cardInfosContainerEl.querySelector(".current--info");
    //     gsap.set(currentInfoEl, {
    //       rotateY: `${angle}deg`,
    //     });
    //   }
    //
    //   function resetCardTransforms(e) {
    //     const card = e.currentTarget;
    //     const currentInfoEl = cardInfosContainerEl.querySelector(".current--info");
    //     gsap.set(card, {
    //       "$current-card-rotation-offset": 0,
    //     });
    //     gsap.set(currentInfoEl, {
    //       rotateY: 0,
    //     });
    //   }
    //
    //   function initCardEvents() {
    //     const currentCardEl = cardsContainerEl.querySelector(".current--card");
    //     currentCardEl.addEventListener("pointermove", updateCard);
    //     currentCardEl.addEventListener("pointerout", (e) => {
    //       resetCardTransforms(e);
    //     });
    //   }
    //
    //   initCardEvents()
    //
    //   function removeCardEvents(card) {
    //     card.removeEventListener("pointermove", updateCard);
    //   }
    //
    //   function init() {
    //     let tl = gsap.timeline();
    //     tl.to(cardsContainerEl.children, {
    //       delay: 0.15,
    //       duration: 0.5,
    //       stagger: {
    //         ease: "power4.inOut",
    //         from: "right",
    //         amount: 0.1,
    //       },
    //       "$card-translateY-offset": "0%",
    //     }).to(cardInfosContainerEl.querySelector(".current--info").querySelectorAll(".text"), {
    //       delay: 0.5,
    //       duration: 0.4,
    //       stagger: 0.1,
    //       opacity: 1,
    //       translateY: 0,
    //     }).to(
    //         [buttons.prev, buttons.next],
    //         {
    //           duration: 0.4,
    //           opacity: 1,
    //           pointerEvents: "all",
    //         },
    //         "-=0.4"
    //     );
    //   }
    //
    //   const waitForImages = () => {
    //     const images = [...document.querySelectorAll("img")];
    //     const totalImages = images.length;
    //     let loadedImages = 0;
    //     const loaderEl = document.querySelector(".loader span");
    //
    //     gsap.set(cardsContainerEl.children, {
    //       "$card-translateY-offset": "100vh",
    //     });
    //     gsap.set(cardInfosContainerEl.querySelector(".current--info").querySelectorAll(".text"), {
    //       translateY: "40px",
    //       opacity: 0,
    //     });
    //     gsap.set([buttons.prev, buttons.next], {
    //       pointerEvents: "none",
    //       opacity: "0",
    //     });
    //
    //     images.forEach((image) => {
    //       imagesLoaded(image, (instance) => {
    //         if (instance.isComplete) {
    //           loadedImages++;
    //           let loadProgress = loadedImages / totalImages;
    //
    //           gsap.to(loaderEl, {
    //             duration: 1,
    //             scaleX: loadProgress,
    //             backgroundColor: `hsl(${loadProgress * 120}, 100%, 50%`,
    //           });
    //
    //           if (totalImages === loadedImages) {
    //             gsap.timeline()
    //                 .to(".loading__wrapper", {
    //                   duration: 0.8,
    //                   opacity: 0,
    //                   pointerEvents: "none",
    //                 })
    //                 .call(() => init());
    //           }
    //         }
    //       });
    //     });
    //   };
    //   waitForImages();
    //
    //
    //   //this.swapCards(direction)
    //   //this.swapCardsClass()
    //   //this.changeInfo(direction)
    //   //this.updateCard(e)
    //   //this.resetCardTransforms(e)
    //   //this.initCardEvents()
    //   //this.removeCardEvents(card)
    //   //this.init()
    // },
    // swapCards(direction) {
    //   this.changeInfo(direction)
    //   this.swapCardsClass()
    //   this.removeCardEvents(currentCardEl);
    // },
    // swapCardsClass() {
    //   currentCardEl.classList.remove("current--card");
    //   previousCardEl.classList.remove("previous--card");
    //   nextCardEl.classList.remove("next--card");
    //
    //   currentBgImageEl.cswapCardsClasslassList.remove("current--image");
    //   previousBgImageEl.classList.remove("previous--image");
    //   nextBgImageEl.classList.remove("next--image");
    //
    //   currentCardEl.style.zIndex = "50";
    //   currentBgImageEl.style.zIndex = "-2";
    //
    //   if (direction === "right") {
    //     previousCardEl.style.zIndex = "20";
    //     nextCardEl.style.zIndex = "30";
    //
    //     nextBgImageEl.style.zIndex = "-1";
    //
    //     currentCardEl.classList.add("previous--card");
    //     previousCardEl.classList.add("next--card");
    //     nextCardEl.classList.add("current--card");
    //
    //     currentBgImageEl.classList.add("previous--image");
    //     previousBgImageEl.classList.add("next--image");
    //     nextBgImageEl.classList.add("current--image");
    //   } else if (direction === "left") {
    //     previousCardEl.style.zIndex = "30";
    //     nextCardEl.style.zIndex = "20";
    //
    //     previousBgImageEl.style.zIndex = "-1";
    //
    //     currentCardEl.classList.add("next--card");
    //     previousCardEl.classList.add("current--card");
    //     nextCardEl.classList.add("previous--card");
    //
    //     currentBgImageEl.classList.add("next--image");
    //     previousBgImageEl.classList.add("current--image");
    //     nextBgImageEl.classList.add("previous--image");
    //   }
    // },
    // changeInfo(direction) {
    //   let currentInfoEl = cardInfosContainerEl.querySelector(".current--info");
    //   let previousInfoEl = cardInfosContainerEl.querySelector(".previous--info");
    //   let nextInfoEl = cardInfosContainerEl.querySelector(".next--info");
    //
    //   gsap.timeline()
    //       .to([buttons.prev, buttons.next], {
    //         duration: 0.2,
    //         opacity: 0.5,
    //         pointerEvents: "none",
    //       })
    //       .to(
    //           currentInfoEl.querySelectorAll(".text"),
    //           {
    //             duration: 0.4,
    //             stagger: 0.1,
    //             translateY: "-120px",
    //             opacity: 0,
    //           },
    //           "-="
    //       )
    //       .call(() => {
    //         swapInfosClass(direction);
    //       })
    //       .call(() => this.initCardEvents())
    //       .fromTo(
    //           direction === "right"
    //               ? nextInfoEl.querySelectorAll(".text")
    //               : previousInfoEl.querySelectorAll(".text"),
    //           {
    //             opacity: 0,
    //             translateY: "40px",
    //           },
    //           {
    //             duration: 0.4,
    //             stagger: 0.1,
    //             translateY: "0px",
    //             opacity: 1,
    //           }
    //       )
    //       .to([buttons.prev, buttons.next], {
    //         duration: 0.2,
    //         opacity: 1,
    //         pointerEvents: "all",
    //       });
    //
    //   function swapInfosClass() {
    //     currentInfoEl.classList.remove("current--info");
    //     previousInfoEl.classList.remove("previous--info");
    //     nextInfoEl.classList.remove("next--info");
    //
    //     if (direction === "right") {
    //       currentInfoEl.classList.add("previous--info");
    //       nextInfoEl.classList.add("current--info");
    //       previousInfoEl.classList.add("next--info");
    //     } else if (direction === "left") {
    //       currentInfoEl.classList.add("next--info");
    //       nextInfoEl.classList.add("previous--info");
    //       previousInfoEl.classList.add("current--info");
    //     }
    //   }
    // },
    // updateCard(e) {
    //   const card = e.currentTarget;
    //   const box = card.getBoundingClientRect();
    //   const centerPosition = {
    //     x: box.left + box.width / 2,
    //     y: box.top + box.height / 2,
    //   };
    //   let angle = Math.atan2(e.pageX - centerPosition.x, 0) * (35 / Math.PI);
    //   gsap.set(card, {
    //     "$current-card-rotation-offset": `${angle}deg`,
    //   });
    //   const currentInfoEl = cardInfosContainerEl.querySelector(".current--info");
    //   gsap.set(currentInfoEl, {
    //     rotateY: `${angle}deg`,
    //   });
    // // },
    // resetCardTransforms(e) {
    //   const card = e.currentTarget;
    //   const currentInfoEl = cardInfosContainerEl.querySelector(".current--info");
    //   gsap.set(card, {
    //     "$current-card-rotation-offset": 0,
    //   });
    //   gsap.set(currentInfoEl, {
    //     rotateY: 0,
    //   });
    // },
    // initCardEvents() {
    //   const currentCardEl = cardsContainerEl.querySelector(".current--card");
    //   currentCardEl.addEventListener("pointermove", this.updateCard);
    //   currentCardEl.addEventListener("pointerout", (e) => {
    //     this.resetCardTransforms(e);
    //   });
    // },
    // removeCardEvents(card) {
    //   card.removeEventListener("pointermove", this.updateCard);
    // },
    // init() {
    //   let tl = gsap.timeline();
    //   tl.to(cardsContainerEl.children, {
    //     delay: 0.15,
    //     duration: 0.5,
    //     stagger: {
    //       ease: "power4.inOut",
    //       from: "right",
    //       amount: 0.1,
    //     },
    //     "$card-translateY-offset": "0%",
    //   }).to(cardInfosContainerEl.querySelector(".current--info").querySelectorAll(".text"), {
    //     delay: 0.5,
    //     duration: 0.4,
    //     stagger: 0.1,
    //     opacity: 1,
    //     translateY: 0,
    //   }).to(
    //       [buttons.prev, buttons.next],
    //       {
    //         duration: 0.4,
    //         opacity: 1,
    //         pointerEvents: "all",
    //       },
    //       "-=0.4"
    //   );
    // }
  }
}
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}

.img-list {
  display: grid;
  align-items: flex-end;
  justify-items: flex-end;
}

ul {
  position: absolute;
  color: white;
  list-style: none;
  font-variant: small-caps;
  font-size: 18px;
  line-height: 22px;
}
</style>
