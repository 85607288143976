<template>
  <div id="app">
    <gallery :cleared-json="clearedJson" />
  </div>
</template>

<script>
import Gallery from "@/components/gallery.vue";
export default {
  data: function() {
    return {
      clearedJson: []
    }
  },
  components: {
    Gallery,
  },
  created() {
    fetch('/gallery.json')
        .then(response => response.json())
        .then(photosData => this.clearedJson = photosData[0][1])
    return this.clearedJson
  }
}
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>